// src/_API/_uriConstructor.js

export const uriConstructor = (section, idData = null, params = {}) => {
    console.log("Construyendo URI para la sección:", section, "con idData:", idData, "y params:", params);

    if (!section) {
        throw new Error("Section is undefined or invalid. Please provide a valid section.");
    }

    // Formatea la sección reemplazando puntos por doble guión bajo
    const formattedSection = section.replace(/\./g, '__');

    // Definición de los endpoints disponibles
    const URI_SELECTED = {
        // CLAIMS
        "CLAIMS__CLAIM_CREATE": () => `/reclamos/`,
        "CLAIMS__CLAIM_UPDATE": (id) => `/reclamos/${id}/`,
        "CLAIMS__CLAIM_DELETE": (id) => `/reclamos/${id}/`,
        "CLAIMS__CLAIM_GET_LIST": () => `/reclamos/`,
        "CLAIMS__CLAIM_GET_DETAIL": (id) => `/reclamos/${id}/`,
        "CLAIMS__CLAIM_UPDATE_STATUS": (id) => `/reclamos/${id}/update-status/`,

        // USERS
        "USERS__AUTH_REGISTER_USER": () => `/users/register/`,
        "USERS__AUTH_LOGIN_USER": () => `/token/`,
        "USERS__AUTH_RECOVERY_USER": () => `/user/recovery`,
        "USERS__AUTH_LOGOUT_USER": () => `/user/logout/`,
        "USERS__AUTH_GET_DETAIL_USER_LOGGED": (id) => `/users/${id}/`,
        "USERS__AUTH_UPDATE_USER_LOGGED": (id) => `/users/${id}/`,
        "USERS__LIST_ALL_USERS": () => `/users/`,
        "USERS__RESET_PASSWORD": () => `password-reset-request/`,  // Solicitud de restablecimiento
        "USERS__RESET_PASSWORD_CONFIRM": ({ uid, token }) => `password-reset-confirm/${uid}/${token}/`,

        // USERS ASSOCIATED
        "USERS__ASSOCIATED_USER_GROUP_GET_LIST": () => `/users/associated-users/register/`,
        "USERS__ASSOCIATED_USER_GET_LIST": () => `/associated-users/register/`,
        "USERS__ASSOCIATED_USER_CREATE": () => `/associated-users/register/`,
        "USERS__ASSOCIATED_USER_UPDATE": (id) => `/associated-users/${id}/`,
        "USERS__ASSOCIATED_USER_DELETE": (id) => `/associated-users/${id}/`,
        "USERS__ASSOCIATED_USER_GET_DETAIL": (id) => `/associated-users/${id}/`,

        // EMAIL
        "EMAIL__SENDGRID_CONFIG_GET": () => `/sendgrid-config/`,
        "EMAIL__SENDGRID_CONFIG_UPDATE": () => `/sendgrid-config/`,
        "EMAIL__SENDGRID_WEBHOOK": () => `/sendgrid-webhook/`,
        "EMAIL__ATTACHMENT_QR_GET": (id) => `/attachment-qr/${id}/`,

        // CONSORTIUMS
        "CONSORTIUMS__BANK_CREATE": () => `/consorcios/bancos/`,
        "CONSORTIUMS__BANK_UPDATE": (id) => `/bancos/${id}/`,
        "CONSORTIUMS__BANK_DELETE": (id) => `/bancos/${id}/`,
        "CONSORTIUMS__BANK_GET_LIST": () => `/consorcios/bancos/`,
        "CONSORTIUMS__BANK_GET_DETAIL": (id) => `/bancos/${id}/`,

        "CONSORTIUMS__CONSORTIUM_CREATE": () => `/consorcios/consorcios/`,
        "CONSORTIUMS__CONSORTIUM_UPDATE": (id) => `/consorcios/${id}/`,
        "CONSORTIUMS__CONSORTIUM_DELETE": (id) => `/consorcios/${id}/`,
        "CONSORTIUMS__CONSORTIUM_GET_LIST": () => `/consorcios/consorcios/`,
        "CONSORTIUMS__CONSORTIUM_GET_DETAIL": (id) => `/consorcios/${id}/`,

        "CONSORTIUMS__PERIOD_CREATE": () => `/periodos/`,
        "CONSORTIUMS__PERIOD_UPDATE": (id) => `/periodos/${id}/cerrar/`,
        "CONSORTIUMS__PERIOD_GET_LIST": () => `/periodos/listar/`,
        "CONSORTIUMS__PERIOD_GET_DETAIL": (id) => `/periodos/${id}/`,
        "CONSORTIUMS__PERIOD_DELETE": (id) => `/periodos/${id}/delete/`,
        "CONSORTIUMS__PERIOD_VERIFY": ({ consorcioId, fecha }) => `/consorcios/${consorcioId}/verificar_periodo/?fecha=${fecha}`,

        "CONSORTIUMS__CASH_CREATE": () => `/cajas/`,
        "CONSORTIUMS__CASH_UPDATE": (id) => `/cajas/${id}/`,
        "CONSORTIUMS__CASH_DELETE": (id) => `/cajas/${id}/`,
        "CONSORTIUMS__CASH_GET_LIST": () => `/cajas/`,
        "CONSORTIUMS__CASH_GET_DETAIL": (id) => `/cajas/${id}/`,

       "CONSORTIUMS__OWN_CREATE": () => `/propietarios/`,  // Endpoint para crear un propietario
        "CONSORTIUMS__OWN_UPDATE": (id) => `/propietarios/${id}/`,  // Endpoint para actualizar un propietario específico
        "CONSORTIUMS__OWN_DELETE": (id) => `/propietarios/${id}/`,  // Endpoint para eliminar un propietario específico
        
        "CONSORTIUMS__OWN_GET_DETAIL": (id) => `/propietarios/${id}/`,  // Endpoint para obtener el detalle de un propietario específico
        
       
        "CONSORTIUMS__OWN_GET_LIST": () => `/propietarios/`,  // Obtener la lista general de propietarios
        "CONSORTIUMS__OWN_GET_LIST_BY_CONSORTIUM": (consorcioId) => `/consorcios/${consorcioId}/propietarios/`,  // Obtener propietarios de un consorcio específic

        // COLLECTIONS
        "COLLECTIONS__COLLECTION_CREATE": () => `/cobranzas/`,
        "COLLECTIONS__COLLECTION_UPDATE": (id) => `/cobranzas/${id}/`,
        "COLLECTIONS__COLLECTION_DELETE": (id) => `/cobranzas/${id}/`,
        "COLLECTIONS__COLLECTION_GET_LIST": () => `/cobranzas/`,
        "COLLECTIONS__COLLECTION_GET_DETAIL": (id) => `/cobranzas/${id}/`,

        // MOVEMENTS
        "CONSORTIUMS__MOVEMENT_CREATE": () => `/consorcios/movimientos/`,
        "CONSORTIUMS__MOVEMENT_UPDATE": (id) => `/consorcios/movimientos/${id}/`,
        "CONSORTIUMS__MOVEMENT_DELETE": (id) => `/consorcios/movimientos/${id}/`,
        "CONSORTIUMS__MOVEMENT_GET_LIST": () => `/consorcios/movimientos/`,
        "CONSORTIUMS__MOVEMENT_GET_DETAIL": (id) => `/consorcios/movimientos/${id}/`,

        "INVESTMENTS__INVESTMENT_CREATE": () => `/inversiones/`,
        "INVESTMENTS__INVESTMENT_UPDATE": (id) => `/inversiones/${id}/`,
        "INVESTMENTS__INVESTMENT_DELETE": (id) => `/inversiones/${id}/`,
        "INVESTMENTS__INVESTMENT_GET_LIST": () => `/inversiones/`,
        "INVESTMENTS__INVESTMENT_GET_DETAIL": (id) => `/inversiones/${id}/`,

        "RECONCILIATIONS__RECONCILIATION_CREATE": () => `/conciliaciones/`,
        "RECONCILIATIONS__RECONCILIATION_UPDATE": (id) => `/conciliaciones/${id}/`,
        "RECONCILIATIONS__RECONCILIATION_DELETE": (id) => `/conciliaciones/${id}/`,
        "RECONCILIATIONS__RECONCILIATION_GET_DETAIL": (id) => `/conciliaciones/${id}/`,
        "RECONCILIATIONS__IMPORT_BANK_STATEMENT": () => `/conciliaciones/importar/`,
        "RECONCILIATIONS__GET_MOVEMENTS": (id) => `/conciliaciones/${id}/movimientos/`,
        "RECONCILIATIONS__UPDATE_STATUS": (id) => `/conciliaciones/${id}/actualizar_estado/`,
        "RECONCILIATIONS__CONCILIATE_BATCH": (id) => `/conciliaciones/${id}/conciliar_lote/`,
        "RECONCILIATIONS__CONCILIATE_MOVEMENT": () => `/conciliaciones/conciliar-movimiento/`,
        "RECONCILIATIONS__CONCILIAR_LOTE": (id) => `/conciliaciones/${id}/conciliar_lote/`,
        "RECONCILIATIONS__RECONCILIATION_GET_LIST": () => `/conciliaciones/`,
        "RECONCILIATIONS__GET_RECONCILIATION_DATA": (id) => `/conciliaciones/${id}/datos/`,
        "RECONCILIATIONS__CONFIRM_RECONCILIATION": (id) => `/conciliaciones/${id}/confirmar/`,

        "CONSORTIUMS__CURRENT_ACCOUNT_CASH_GET_LIST": () => `/consorcios/cuenta_corriente_caja/`,
        "CONSORTIUMS__BANK_CHECKING_ACCOUNT_GET_LIST": () => `/cuenta-corriente-banco/`,

        // MOVEMENTS - PAYMENTS
        "MOVEMENTS__PAYMENT_CREATE": () => `/pagos/`,
        "MOVEMENTS__PAYMENT_UPDATE": (id) => `/pagos/${id}/`,
        "MOVEMENTS__PAYMENT_DELETE": (id) => `/pagos/${id}/`,
        "MOVEMENTS__PAYMENT_GET_LIST": () => `/pagos/`,
        "MOVEMENTS__PAYMENT_GET_DETAIL": (id) => `/pagos/${id}/`,

        // LIQUIDATIONS
        "LIQUIDATIONS__GENERATE": () => `/liquidacion/generar/`,
        "LIQUIDATIONS__PREVIEW": () => `/liquidacion/previsualizar/`,
        "LIQUIDATIONS__EXPENSES_BY_UF": (id) => `/liquidacion/expensas-por-uf/${id}/`,
        "LIQUIDATIONS__RECENT": () => `/liquidaciones-recientes/`,
        "LIQUIDATIONS__DETAIL": (id) => `/liquidacion/detalle/${id}/`,
        "LIQUIDATIONS__DELETE": (id) => `/liquidacion/eliminar/${id}/`,
        "LIQUIDATIONS__EDIT": (id) => `/liquidacion/editar/${id}/`,

        // Grupo Porcentual
        "PERCENTAGE_GROUPS__LIST": () => `/grupos-porcentuales/`,
        "PERCENTAGE_GROUPS__DETAIL": (id) => `/grupos-porcentuales/${id}/`,
        "PERCENTAGE_GROUPS__CREATE": () => `/grupos-porcentuales/`,
        "PERCENTAGE_GROUPS__UPDATE": (id) => `/grupos-porcentuales/${id}/`,
        "PERCENTAGE_GROUPS__DELETE": (id) => `/grupos-porcentuales/${id}/`,

        // Porcentaje Unidad
        "UNIT_PERCENTAGES__LIST": () => `/porcentajes-unidad/`,
        "UNIT_PERCENTAGES__DETAIL": (id) => `/porcentajes-unidad/${id}/`,
        "UNIT_PERCENTAGES__CREATE": () => `/porcentajes-unidad/`,
        "UNIT_PERCENTAGES__UPDATE": (id) => `/porcentajes-unidad/${id}/`,
        "UNIT_PERCENTAGES__DELETE": (id) => `/porcentajes-unidad/${id}/`,

        // Grupo
        "GROUPS__LIST": () => `/grupos/`,
        "GROUPS__DETAIL": (id) => `/grupos/${id}/`,
        "GROUPS__CREATE": () => `/grupos/`,
        "GROUPS__UPDATE": (id) => `/grupos/${id}/`,
        "GROUPS__DELETE": (id) => `/grupos/${id}/`,

        // Concepto Expensa
        "EXPENSE_CONCEPTS__LIST": () => `/conceptos-expensa/`,
        "EXPENSE_CONCEPTS__DETAIL": (id) => `/conceptos-expensa/${id}/`,
        "EXPENSE_CONCEPTS__CREATE": () => `/conceptos-expensa/`,
        "EXPENSE_CONCEPTS__UPDATE": (id) => `/conceptos-expensa/${id}/`,
        "EXPENSE_CONCEPTS__DELETE": (id) => `/conceptos-expensa/${id}/`,



        "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_GET_LIST": () => `/cuentascorrientes_propietarios/`,
        "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_CREATE": () => `/cuentascorrientes_propietarios/`,
        "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_UPDATE": (id) => `/cuentascorrientes_propietarios/${id}/`,
        "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_DELETE": (id) => `/cuentascorrientes_propietarios/${id}/`,
        "LIQUIDATIONS__CUENTAS_CORRIENTES_PROP_GET_DETAIL": (id) => `/cuentascorrientes_propietarios/${id}/`,

        "RESUMEN__CONSORCIO_GET_LIST": (id) => `/resumen/${id}/`,
        "RESUMEN__CONSORCIO_GET_PDF": (id) => `/resumen/${id}/pdf/`,

        // MOVEMENTS - EXPENSES SECTOR
        "MOVEMENTS__EXPENSES_SECTOR_CREATE": () => `/rubros/`,
        "MOVEMENTS__EXPENSES_SECTOR_UPDATE": (id) => `/rubros/${id}/`,
        "MOVEMENTS__EXPENSES_SECTOR_DELETE": (id) => `/rubros/${id}/`,
        "MOVEMENTS__EXPENSES_SECTOR_GET_LIST": () => `/rubros/`,
        "MOVEMENTS__EXPENSES_SECTOR_GET_DETAIL": (id) => `/rubros/${id}/`,

        // MOVEMENTS - EXPENSES ACCOUNT
        "MOVEMENTS__EXPENSES_ACCOUNT_CREATE": () => `/cuentas/`,
        "MOVEMENTS__EXPENSES_ACCOUNT_UPDATE": (id) => `/cuentas/${id}/`,
        "MOVEMENTS__EXPENSES_ACCOUNT_DELETE": (id) => `/cuentas/${id}/`,
        "MOVEMENTS__EXPENSES_ACCOUNT_GET_LIST": () => `/cuentas/`,
        "MOVEMENTS__EXPENSES_ACCOUNT_GET_DETAIL": (id) => `/cuentas/${id}/`,

        // MOVEMENTS - EXPENSES PROVIDER
        "MOVEMENTS__EXPENSES_PROVIDER_CREATE": () => `/proveedores/`,
        "MOVEMENTS__EXPENSES_PROVIDER_UPDATE": (id) => `/proveedores/${id}/`,
        "MOVEMENTS__EXPENSES_PROVIDER_DELETE": (id) => `/proveedores/${id}/`,
        "MOVEMENTS__EXPENSES_PROVIDER_GET_LIST": () => `/proveedores/`,
        "MOVEMENTS__EXPENSES_PROVIDER_GET_DETAIL": (id) => `/proveedores/${id}/`,

        // MOVEMENTS - EXPENSES EXPENSE
        "MOVEMENTS__EXPENSES_EXPENSE_CREATE": () => `/gastos/`,
        "MOVEMENTS__EXPENSES_EXPENSE_UPDATE": (id) => `/gastos/${id}/`,
        "MOVEMENTS__EXPENSES_EXPENSE_DELETE": (id) => `/gastos/${id}/`,
        "MOVEMENTS__EXPENSES_EXPENSE_GET_LIST": () => `/gastos/`,
        "MOVEMENTS__EXPENSES_EXPENSE_GET_DETAIL": (id) => `/gastos/${id}/`,

        // MOVEMENTS - EXPENSES CTA CTE PROVIDER
        "MOVEMENTS__EXPENSES_CTA_CTE_PROVIDER_GET_LIST": (id) => `/detalle_cuenta_corriente/${id}/`,
        "EXPENSES__REPORT_GET": () => `/gastos/reporte/`,
        "EXPENSES__FACTURAS_NO_PAGADAS_GET": () => `/gastos/facturas_no_pagadas/`,
        "SUPPLIERS__SUPPLIER_GET_LIST": () => `/proveedores/`,
        "MOVEMENTS__PAYMENTS_EFFECTUATED_LIST": () => `/pagos-efectuados/`,  // new entry for pagos-efectuados
        "CONSORTIUMS__RESERVA_CREATE": () => `/reservas/`,
        "CONSORTIUMS__RESERVA_GET_LIST": () => `/reservas/`,

        // NUEVOS ENDPOINTS PARA AMENITIS
        "CONSORTIUMS__AMENITI_CREATE": () => `/ammenitis/`,  // Endpoint para crear un ameniti
        "CONSORTIUMS__AMENITI_UPDATE": (id) => `/ammenitis/${id}/`,  // Endpoint para actualizar un ameniti
        "CONSORTIUMS__AMENITI_DELETE": (id) => `/ammenitis/${id}/`,  // Endpoint para eliminar un ameniti
        "CONSORTIUMS__AMENITI_GET_LIST": () => `/ammenitis/`,  // Endpoint para obtener la lista de amenitis
        "CONSORTIUMS__AMENITI_GET_DETAIL": (id) => `/ammenitis/${id}/`,  // Endpoint para obtener el detalle de un ameniti específico



    };

    if (!URI_SELECTED[formattedSection]) {
        throw new Error(`Endpoint for section ${formattedSection} is not defined.`);
    }

    // Si la sección requiere `idData`, pásalo como argumento; de lo contrario, usa `params`
    return idData !== null
        ? URI_SELECTED[formattedSection](idData)
        : URI_SELECTED[formattedSection](params);
};