// src/_Hooks/_Context/useDataUserAuthenticated.js

import { useJwt } from "react-jwt";
import { ApiConsume } from "../../_API/ApiConsume";
import { createContext, useState, useEffect } from "react";
import { useNotifications } from 'reapop';

export const UseDataUserContext = createContext();

export const UseDataUserProvider = ({ children }) => {

    let isLogged = false;
    let token = '';

    if (localStorage.getItem('USER_AUTH') !== null) {
        isLogged = true;
        token = localStorage.getItem('USER_AUTH');
    }

    if (sessionStorage.getItem('USER_AUTH') !== null) {
        isLogged = true;
        token = sessionStorage.getItem('USER_AUTH');
    }

    const { notify } = useNotifications();

    const { decodedToken } = useJwt(token);

    const [useInfoUserAuth, setInfoUserAuth] = useState({
        isLoading: true, data: {
            id: 0, first_name: "", last_name: "", domicilio: "",
            telefono: "", cuit: "", provincia: "", codigo_postal: "",
            afip: "", username: "", email: "", role: ""
        }
    });

    const [useInputProfileUserValue, setInputProfileUserValue] = useState({
        first_name: "", last_name: "", domicilio: "",
        telefono: "", cuit: "", provincia: "", codigo_postal: "",
        afip: "", username: "", email: "", role: ""
    });

    useEffect(() => {
        const fetchUserData = async () => {
            if (isLogged && decodedToken && decodedToken.user_id) {
                try {
                    const res = await ApiConsume('USERS__AUTH_GET_DETAIL_USER_LOGGED', 'GET', {}, decodedToken.user_id, token);
                    const DATA = res.data;
                    setInfoUserAuth({
                        isLoading: false, data: {
                            id: DATA.id, first_name: DATA.first_name, last_name: DATA.last_name, domicilio: DATA.domicilio,
                            telefono: DATA.telefono, cuit: DATA.cuit, provincia: DATA.provincia, codigo_postal: DATA.codigo_postal,
                            afip: DATA.afip, username: DATA.username, email: DATA.email, role: DATA.role, token: token
                        }
                    });

                    setInputProfileUserValue({
                        first_name: DATA.first_name, last_name: DATA.last_name, domicilio: DATA.domicilio,
                        telefono: DATA.telefono, cuit: DATA.cuit, provincia: DATA.provincia, codigo_postal: DATA.codigo_postal,
                        afip: DATA.afip, username: DATA.username, email: DATA.email, role: DATA.role
                    });
                } catch (error) {
                    setInfoUserAuth({
                        isLoading: false, data: {
                            id: 0, first_name: "", last_name: "", domicilio: "",
                            telefono: "", cuit: "", provincia: "", codigo_postal: "",
                            afip: "", username: "", email: "", role: ""
                        }
                    });
                    setInputProfileUserValue({
                        first_name: "", last_name: "", domicilio: "",
                        telefono: "", cuit: "", provincia: "", codigo_postal: "",
                        afip: "", username: "", email: "", role: ""
                    });
                    notify('Error al obtener los datos del usuario. Inténtalo nuevamente.', 'error');
                }
            } else {
                setInfoUserAuth({
                    isLoading: false, data: {
                        id: 0, first_name: "", last_name: "", domicilio: "",
                        telefono: "", cuit: "", provincia: "", codigo_postal: "",
                        afip: "", username: "", email: "", role: ""
                    }
                });
                setInputProfileUserValue({
                    first_name: "", last_name: "", domicilio: "",
                    telefono: "", cuit: "", provincia: "", codigo_postal: "",
                    afip: "", username: "", email: "", role: ""
                });
            }
        };

        fetchUserData();
    }, [isLogged, decodedToken, token, notify]);

    const onUpdateProfileUserAuth = async (e) => {
        e.preventDefault();

        try {
            const API = await ApiConsume('USERS__AUTH_UPDATE_USER_LOGGED', 'PUT', useInputProfileUserValue, useInfoUserAuth.data.id, token);

            if (API.msg_success) {
                notify(API.msg_success, 'success');
                // Puedes navegar a otra ruta si es necesario
                // navigate('/panel/configuración/listado/usuarios');

                // Actualizar el estado con los nuevos datos
                setInfoUserAuth({
                    ...useInfoUserAuth,
                    data: {
                        ...useInfoUserAuth.data,
                        ...useInputProfileUserValue
                    }
                });
            } else {
                notify('Error al actualizar el perfil. Inténtalo nuevamente.', 'error');
            }
        } catch (error) {
            notify('Error al actualizar el perfil. Inténtalo nuevamente.', 'error');
        }
    }

    return (
        <UseDataUserContext.Provider value={{
            decodedToken,
            useInfoUserAuth,
            setInfoUserAuth,
            useInputProfileUserValue,
            setInputProfileUserValue,
            onUpdateProfileUserAuth
        }}>
            {children}
        </UseDataUserContext.Provider>
    );
}