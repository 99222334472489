// src/_API/ApiConsume.js

import axios from 'axios';
import { uriConstructor } from './_uriConstructor';
import './axiosConfig'; // Asegúrate de importar la configuración de Axios

export const ApiConsume = async (
  section = "",
  method = "GET",
  BODY = {},
  idData = null,
  TOKEN = "",
  isFormData = false,
  PARAMS = {},
  shouldRedirectOn401 = true
) => {
  // Validación de sección
  if (!section) {
    console.error("Section is undefined or empty.");
    return {
      status: 400,
      isLoading: false,
      msg_error: "Section is undefined or invalid.",
      data: idData ? {} : [],
    };
  }

  // Construcción de la URI usando el constructor
  let URI;
  try {
    URI = uriConstructor(section, idData); // Llamada correcta a la función
    console.log(`URI construida: ${URI}`);
  } catch (error) {
    console.error(error.message);
    return {
      status: 400,
      isLoading: false,
      msg_error: error.message,
      data: idData ? {} : [],
    };
  }

  let DATA_RES = {
    status: 0,
    isLoading: true,
    msg_success: "",
    msg_error: "",
    data: idData ? {} : [],
  };

  // Configuración de cabeceras
  let HEADER_config = {};
  const accessToken = TOKEN || localStorage.getItem("USER_AUTH") || sessionStorage.getItem("USER_AUTH");

  if (accessToken) {
    HEADER_config["Authorization"] = `Bearer ${accessToken}`;
  }
  HEADER_config["Content-Type"] = isFormData ? "multipart/form-data" : "application/json";

  try {
    let AXIOS_RES;

    // Lógica de la solicitud según el método HTTP
    switch (method.toUpperCase()) {
      case "GET":
        AXIOS_RES = await axios.get(URI, { headers: HEADER_config, params: PARAMS });
        break;
      case "POST":
        AXIOS_RES = await axios.post(URI, BODY, { headers: HEADER_config, params: PARAMS });
        break;
      case "PUT":
        AXIOS_RES = await axios.put(URI, BODY, { headers: HEADER_config });
        break;
      case "DELETE":
        AXIOS_RES = await axios.delete(URI, { headers: HEADER_config });
        break;
      default:
        throw new Error(`Invalid HTTP Method: ${method}`);
    }

    // Manejo de respuesta exitosa
    if (AXIOS_RES && [200, 201, 204].includes(AXIOS_RES.status)) {
      const successMessages = {
        POST: "El recurso ha sido creado con éxito",
        PUT: "El recurso ha sido actualizado con éxito",
        DELETE: "El recurso ha sido eliminado con éxito",
        GET: "El recurso ha sido obtenido con éxito",
      };
      DATA_RES = {
        ...DATA_RES,
        isLoading: false,
        data: AXIOS_RES.data || (idData ? {} : []),
        msg_success: successMessages[method.toUpperCase()] || "Operación completada con éxito",
        status: AXIOS_RES.status,
      };
    }
  } catch (error) {
    // Manejo de errores HTTP y conexión
    const status = error.response?.status;
    const errorMessage = error.response?.data?.detail || error.response?.data || error.message || "Error desconocido";

    DATA_RES = {
      status: status || 500,
      isLoading: false,
      msg_success: "",
      msg_error: errorMessage,
      data: idData ? {} : [],
    };

    // Manejo de redirección en caso de error 401
    if (status === 401 && shouldRedirectOn401) {
      console.error("Autenticación fallida o token expirado.");
      window.location.href = "/"; // Redirige al dashboard o login
    }
  }

  return DATA_RES;
};