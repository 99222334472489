// src/Components/_UI/_NavigationApp.js

import React, { useContext, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartPie, faCog, faChartBar, faBullhorn, faHeadset,
    faFileInvoiceDollar, faBook, faList, faBell, faEnvelopeOpen, faUserCircle,
    faSun, faMoon, faSignOutAlt, faMoneyBillWave, faTable, faEnvelope, faWallet
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Navbar, Badge, Image, Button, Dropdown, Container } from '@themesberg/react-bootstrap';
import { UseDataUserContext } from '../../_Hooks/_Context/useDataUserAuthenticated';
import { Notifications } from "../../_Data/Notifications"; // Importar Notifications
import { ApiConsume } from "../../_API/ApiConsume"; // Importar ApiConsume
import './Sidebar.css';
import { ThemeContext } from '../../_Hooks/_Context/ThemeContext'; // Importar ThemeContext

/**
 * Componente NavItem para la barra lateral.
 */
const NavItem = ({ title, link, icon, isCollapsed, isActive }) => (
    <Nav.Item className={`nav-item ${isActive ? "active" : ""}`}>
        <Nav.Link as={Link} to={link} className="d-flex align-items-center">
            <FontAwesomeIcon icon={icon} className="me-3 sidebar-icon" />
            {!isCollapsed && <span className="sidebar-text">{title}</span>}
        </Nav.Link>
    </Nav.Item>
);

/**
 * Componente AdminSidebarApp para la barra lateral de administración.
 */
export const AdminSidebarApp = () => {
    const { pathname } = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { useInfoUserAuth } = useContext(UseDataUserContext);
    const { themeMode } = useContext(ThemeContext); // Acceder al tema actual

    const handleMouseEnter = () => setIsCollapsed(false);
    const handleMouseLeave = () => setIsCollapsed(true);

    const navItems = [
        { title: "Dashboard", link: '/', icon: faChartPie },
        { title: "Configuración", link: "/panel/configuración", icon: faCog },
        { title: "Finanzas", link: "/panel/finanzas", icon: faChartBar },
        { title: "Movimientos", link: "/panel/movimientos", icon: faBullhorn },
        { title: "Servicios", link: "/panel/servicios", icon: faHeadset },
        { title: "Liquidación", link: "/panel/liquidación", icon: faFileInvoiceDollar },
        { title: "Reportes", link: "/panel/reportes", icon: faTable },
        { title: "Cuentas Corrientes", link: "/panel/ctas_ctes", icon: faWallet }, 
        { title: "Sueldos", link: "/panel/sueldos", icon: faMoneyBillWave }, 
        { title: "Correo Electronico", link: "/panel/correo", icon: faEnvelope },
        { title: "Manual", link: "/panel/manual-de-procedimiento", icon: faBook },
        { title: "Requerimientos", link: "/panel/requerimientos", icon: faList },
    ];

    return (
        <>
            {/* Navbar para dispositivos móviles */}
            <Navbar expand={false} variant="dark" className="d-md-none app_sidebar_navigation">
                <Navbar.Brand as={Link} to="/dashboard">
                    {/* Logo o Nombre de la Aplicación */}
                    <img src="/path/to/logo.png" alt="Logo" className="logo" />
                </Navbar.Brand>
            </Navbar>

            {/* Barra lateral con SimpleBar para scroll personalizado */}
            <SimpleBar
                className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${themeMode === 'dark' ? 'sidebar-dark' : 'sidebar-light'}`} // Añadir clase según tema
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="sidebar-inner p-3">
                    <Nav className="flex-column">
                        {navItems.map((item) => (
                            <NavItem
                                key={item.link}
                                title={item.title}
                                link={item.link}
                                icon={item.icon}
                                isCollapsed={isCollapsed}
                                isActive={item.link === pathname}
                            />
                        ))}
                    </Nav>
                </div>
            </SimpleBar>
        </>
    );
};

/**
 * Componente HeaderNavbar para la barra de navegación superior.
 */
export const HeaderNavbar = () => {
    const { useInfoUserAuth } = useContext(UseDataUserContext);
    const { themeMode, toggleTheme } = useContext(ThemeContext); // Acceder al tema y función de toggle
    const [notifications, setNotifications] = useState(Notifications); // Importar Notifications
    const [online, setOnline] = useState(navigator.onLine);
    const token = localStorage.getItem('USER_AUTH') || sessionStorage.getItem('USER_AUTH') || '';

    const handleLogout = () => {
        ApiConsume('USERS__AUTH_LOGOUT_USER', 'POST', { refresh_token: token }) // Importar ApiConsume
            .then(() => {
                localStorage.removeItem('USER_AUTH');
                sessionStorage.removeItem('USER_AUTH');
                window.location.href = '/';
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 navbar-theme-primary">
            <Container fluid className="px-0"> {/* Importar Container */}
                <div className="d-flex justify-content-between w-100">
                    <Nav className="align-items-center ms-auto">
                        {/* Botón para modo oscuro */}
                        <Button 
                            variant="link" 
                            onClick={toggleTheme} 
                            className="text-light me-3"
                        >
                            <FontAwesomeIcon icon={themeMode === 'light' ? faMoon : faSun} size="lg" />
                        </Button>

                        {/* Estado de conexión */}
                        <Badge pill bg={online ? "success" : "danger"} className="me-3"> {/* Importar Badge */}
                            {online ? "Online" : "Offline"}
                        </Badge>

                        {/* Notificaciones */}
                        <Dropdown as={Nav.Item} className="me-3"> {/* Importar Dropdown */}
                            <Dropdown.Toggle as={Nav.Link} className="position-relative">
                                <FontAwesomeIcon icon={faBell} size="lg" />
                                {notifications.some(n => !n.read) && (
                                    <Badge pill bg="danger" className="position-absolute top-0 start-100 translate-middle"> {/* Importar Badge */}
                                        {notifications.filter(n => !n.read).length}
                                    </Badge>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="notifications-dropdown"> {/* Importar Dropdown */}
                                <div className="dropdown-header">Notificaciones</div>
                                {notifications.length > 0 ? (
                                    notifications.map((notification, index) => (
                                        <Dropdown.Item 
                                            key={index} 
                                            className={!notification.read ? 'fw-bold' : ''}
                                        >
                                            {notification.message}
                                        </Dropdown.Item>
                                    ))
                                ) : (
                                    <Dropdown.Item>No hay notificaciones</Dropdown.Item>
                                )}
                                <Dropdown.Divider />
                                <Dropdown.Item 
                                    onClick={() => setNotifications(notifications.map(n => ({ ...n, read: true })))}
                                >
                                    Marcar todas como leídas
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* Perfil de usuario */}
                        <Dropdown as={Nav.Item}> {/* Importar Dropdown */}
                            <Dropdown.Toggle as={Nav.Link} className="d-flex align-items-center">
                                {!useInfoUserAuth.isLoading && useInfoUserAuth.data && (
                                    <>
                                        <Image 
                                            src={useInfoUserAuth.data.avatar || "https://via.placeholder.com/150"} 
                                            roundedCircle 
                                            width="30" 
                                            height="30" 
                                            className="me-2" 
                                        />
                                        <span className="d-none d-md-inline">
                                            {`${useInfoUserAuth.data.first_name} ${useInfoUserAuth.data.last_name}`}
                                        </span>
                                    </>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" className="dropdown-menu-end"> {/* Importar Dropdown */}
                                <Dropdown.Item 
                                    as={Link} 
                                    to={`/panel/configuración/editar/administrador/${useInfoUserAuth.data.id}`} // Importar Dropdown
                                >
                                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Perfil
                                </Dropdown.Item>

                                <Dropdown.Item as={Link} to="/messages"> {/* Importar Dropdown */}
                                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Mensajes
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleLogout}> {/* Importar Dropdown */}
                                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2 text-danger" /> Cerrar sesión
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </div>
            </Container>
        </Navbar>
    );
}



