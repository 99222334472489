// src/Components/_UI/LayoutPrivate.js

import { ThemeContext } from '../../_Hooks/_Context/ThemeContext';
import { AdminSidebarApp, HeaderNavbar } from "./_NavigationApp"; // Verifica la ruta de _NavigationApp.js para que sea la correcta
import React, { useContext } from "react";


const LayoutPublic = ({ children }) => {
    return <>{children}</>;
};

const LayoutPrivate = ({ children }) => {
    const { theme } = useContext(ThemeContext); // Usa el contexto para leer el tema

    return (
        <>
            {/* Barra lateral de administración */}
            <AdminSidebarApp />

            {/* Contenido principal */}
            <main className={`content ${theme === 'dark' ? 'dark-mode' : ''}`}>
                {/* Navbar en la parte superior */}
                <HeaderNavbar />

                {/* Contenido dentro de la sección principal */}
                <div className="main-content" style={{ margin: '5% 0', wordWrap: 'break-word' }}>
                    {children}
                </div>
            </main>
        </>
    );
};

export { LayoutPublic, LayoutPrivate };